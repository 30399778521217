import React, { useState, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import datas from './datas.json';
import Menu from './components/Menu';

const WrapContainer = styled.section`
  width: 100%;
  height: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
`;

const WrapMenu = styled.div`
  margin-top: 60px;
  width: ${160 * 5}px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flext-start;
  flex-direction: row;
  flex-wrap: wrap;
  flex-flow: row wrap;
  align-content: flex-end;
`;

const WrapAction = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 5%;
`;

const WrapLogo = styled.a`
  width: 291px;
  height: 48px;
`;

const Logo = styled.img`
  width: 100%;
  height: auto;
`;

const Footer = styled.p`
  font-size: 16px;
  text-align: center;
  color: #a8a8a8;
  margin-top: 60px;
`;

const GlobalStyle = createGlobalStyle`
  body {
    font-family: Spoqa Han Sans, Sans-serif;
  }
`;
interface IItem {
  id: number;
  title: string;
  imageUrl: string;
  linkUrl: string;
}

const App: React.FC = () => {
  const [items, setItems] = useState<IItem[]>([]);

  const fetch = (): void => {
    setItems(datas);
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <WrapContainer className="App">
      <Container>
        <WrapLogo href="https://terafunding.com">
          <Logo src="/logo@2x.png" />
        </WrapLogo>

        <WrapMenu>
          {items.map((item) => {
            // NOTE: 현재 사용하지 않으나, 추후 사용 가능성 존재
            if (item.id === 8) return null;
            return (
              <Menu
                key={item.id}
                title={item.title}
                imageUrl={item.imageUrl}
                linkUrl={item.linkUrl}
              />
            );
          })}
        </WrapMenu>
        <Footer>
          - 우리는 금융과 부동산의 풀지 못했던 문제를 혁신적으로 해결합니다. -
        </Footer>
      </Container>
      <GlobalStyle />
    </WrapContainer>
  );
};

export default App;
