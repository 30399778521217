import React from "react"
import styled, { keyframes } from "styled-components"

const shake = keyframes`
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(1px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-2px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(2px, 0, 0);
  }
`

const Wrapper = styled.a`
  width: 160px;
  height: 199px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  box-sizing: border-box;
  &:hover {
    background-color: #f7f7f7;
    border-radius: 30px;
  }
  // animation: ${ shake } 1s linear infinite;
`

const Icon = styled.img`
  width: 80px;
  height: 80px;
  background-color: #fafafa;
  border-radius: 30px;
  ${Wrapper}:hover & {
    background-color: #ffffff;
  }
`

const Title = styled.p`
  font-size: 16px;
  color: #222222;
  text-align: center;
`

interface IProps {
  title: string
  imageUrl: string
  linkUrl: string
}

const Menu = ({ title, imageUrl, linkUrl }: IProps) => (
  <Wrapper href={linkUrl} target="_blank">
    <Icon src={imageUrl} />
    <Title>{title || ""}</Title>
  </Wrapper>
)

export default Menu
